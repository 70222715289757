import * as React from 'react'

import { Container } from '../Container'
import { Col } from '../Col'
import { File } from '../File'
import { FullScreenSection } from '../FullScreenSection'

export const Files = () => (
    <FullScreenSection>
    <Container>
	<h1>Files for the indiscriminating user</h1>
	<p>If you on behalf of a copyright holder object to the distribution of some of these files, you may email me specifying which ones: <a href="mailto:">jbk codehop net</a>
        </p>
	<h4>Added 2021-04-22</h4>
	<p><File href="battalion1.4b/patch-tk.c" />
	<blockquote>Andy Johnson's battalion was the first memorable game I played on Linux in probably early 2010. This patch fixes a crash on session start on OpenBSD and likely other similarly strict platforms.
	</blockquote></p>
	<h4>Added 2021-04-21</h4>
	<p><File href="pp15/setup.inx" />
	<blockquote>Patched setup file for Adobe Premiere Pro 1.5, fixing version check for Windows greater than 5.x. Redistributables setup is also bypassed. Copy your setup files somewhere writeable, then overwrite existing <i>setup.inx</i> with this file. I used sn00pee's SID to make this file.</blockquote>
        <br /><File href="pp15/premierepro_151_update.zip" />
	<blockquote>Premiere Pro 1.5.1 point update from Adobe. (no longer available at their support site)</blockquote>
        </p>
    </Container>
    </FullScreenSection>
)
