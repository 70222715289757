import * as React from 'react'
import Helmet from 'react-helmet'

import { Files } from '../components/Files'
import { Footer } from '../components/Footer'
import FilesLayout from '../layouts'

const FilesPage: React.FC = () => {
  return (
    <FilesLayout>
      <Helmet meta={[{ property: 'og:url', content: 'https://www.codehop.net/' }]} />
      <Files />
      <Footer />
    </FilesLayout>
  )
}

export default FilesPage
