import * as React from 'react'
import { Link } from 'gatsby'
import { FaFile } from 'react-icons/fa'

import {href} from './File.module.scss'

interface HrefProps {
  href: string
  desc: string
}

export const File: React.SFC<HrefProps> = ({ href }) => (
  <Link
    className={href}
    href={"/" + href}
    rel="noopener noreferrer"
    title="{href}"
  >
  <FaFile /> {href}
  </Link>
)
